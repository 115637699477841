.App {
  text-align: center;
  height: 100%;
  
}

body {
  background-color: rgba(0, 0, 0, 0.8);
  margin: 0; 
  /* height: 100%;  */
  font-family: 'Comic Sans MS', sans-serif;
}

.wallet-adapter-button, .wallet-adapter-button-trigger {    
  height: 40px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 0 20px !important;  
  background-color: #512da8;
  cursor: pointer;
  color: white;
}

.header-logo {
  height: 8vmin;
  pointer-events: none;
}

.App-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.8);
}

.header-content {
  display: flex;
  align-items: center;
}

.header-title {
  flex-grow: 1;
  text-align: left;
  color: #61dafb;
  margin: 0;
}

.wallet-button {
  margin-left: 20px;
  height: 40px;
}

.balance-text {
  color: white;
  margin-right: 20px;
}

.balance-container {
  display: flex;
  align-items: center;
  margin-right: 20px;
  border-radius: 5px;
  height: 40px;
  padding-left: 12px;
  background-color: #512da8;
}

.App-main {
  text-align: center;
  /* max-width: 800px; */
  margin: 20px auto;
  min-height: 75vh;
}

.App-main p {
  font-size: 1.2em;
  line-height: 1.6;
  color: #e0e0e0; /* Specific color for p */
}

.App h1 {
  font-size: 2em;
  margin-bottom: 0.5em;
  color: #61dafb; /* Specific color for h1 */
  text-align: center; /* Left align the h1 */
}

.address-input {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px 0;
}

.address-input input {
  width: 100%;
  max-width: 400px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1em;
  background-color: rgba(130, 122, 150, 0.5);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;
  color: white;
}

.address-input input:focus {
  background-color: rgba(130, 122, 150, 0.5); /* Ensure the background color stays the same */
  border-color: #512da8; /* Optional: Change border color on focus */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); /* Optional: Enhance shadow on focus */
  outline: none; /* Remove default focus outline */
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80vw; /* Set maximum width */
  margin: 0 auto; /* Center the container */
}

.address-input {
  margin-bottom: 20px;
}

.tables-container {
  display: flex;
  justify-content: space-between;
  width: 75vw; /* Set width to 75vw */
  max-width: 100%;
  
}

.token-info,
.input-table-container {
  flex: 2;
  margin: 10px;
}

.token-table,
.input-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.token-table td,
.input-table td {
  padding: 10px;
  border: 1px solid #ddd;
  vertical-align: middle;
}

.token-table td img {
  max-width: 50px;
  height: auto;
}

.project-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.social-links a {
  margin-left: 10px;
  color: #512da8;
  font-size: 1.5em;
}

.trader {
  margin: 20px;
}

.button-container input{
  height: 18px !important;
  margin-top: 18px;
  width: 100%;
  background-color: rgba(130, 122, 150, 0.5) ;
}
input[type="number"],
textarea {
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
  background-color: rgba(130, 122, 150, 0.5);
}

/* Hide up and down arrows on number inputs */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::placeholder {
  color: rgba(255, 255, 255, 0.7); /* Example color: white with 70% opacity */
}


input[type=number] {
  -moz-appearance: textfield;
}

.output-box::-webkit-scrollbar {
  width: 12px;
}

.output-box::-webkit-scrollbar-track {
  background: #512da8;
}

.output-box::-webkit-scrollbar-thumb {
  background-color: #512da8;
  border-radius: 6px;
  border: 3px solid #f3f3f3;
}

/* Custom scrollbar for Firefox */
.output-box {
  scrollbar-width: thin;
  scrollbar-color: #512da8;
}

/* Add existing styles */
.output-box {
  min-height: 150px;
  overflow-y: auto;
}

.chart-container {
  margin: 0 auto;
  
  position: relative;
  width: 50% !important;
  height: 500px !important;
}

.tooltip {
  display: none;
  width: 150px;
  text-align: center;
  position: absolute;
  border-radius: 5px;
  background: #512da8 !important;
  color: white;
  padding: 15px;
  border-radius: 5px;
  pointer-events: none;
  z-index: 1000;
}

.styled-textarea {
  min-height: 150px;
  max-height: 150px;
  overflow-y: scroll;
  background-color: rgba(130, 122, 150, 0.5) !important;
  border-radius: 5px;
  border: 1px solid #ccc;
  max-width: 100%;
  padding: 10px;
  white-space: pre-wrap;
}

.loading-spinner {
  border: 16px solid #a8a7a7; /* Light grey */
  border-top: 16px solid #512da8; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: 20px auto; /* Center the spinner */
}

.accept-dialog {
  text-align: center;
  font-size: 18px;  
  padding: 20px;
  border-radius: 10px;
  max-width: 90%;
  margin: 0 auto;
  
}

.accept-dialog h1 {
  color: #512da8;
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.accept-dialog p {
  margin-bottom: 10px;
  font-size: 1em;
}

.accept-dialog button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #512da8;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.accept-dialog button:hover {
  background-color: #311b92;
}

.header-stats {
  display: flex;
  text-align: start;
  font-size: 0.8em;
  align-items: flex-end !important;
  margin-right: 20px !important;
  color: #61dafb;
  margin-left: 20px !important;
}

.platform-stats, .user-stats {
  margin-bottom: 10px;
  margin-right: 20px !important;
}

.footer {
  background-color: #512da8;
  color: white;
  min-height: 65px;
  
  display: flex;
  justify-content: space-between; /* This will place space between the left and right content */
  align-items: center; /* Vertically center the content */
  padding: 0 20px; /* Add padding to the sides for some space */
}

.left-footer,
.right-footer {
  display: flex;
  align-items: center;
}

.accept-dialog a{
  text-decoration: none !important; /* Remove underline from links */
  color: white;
} 

.right-footer p,
.right-footer a {
  margin-left: 20px; /* Add some space between the right footer items */
  color: white; /* Ensure link color matches text color */
  text-decoration: none !important; /* Remove underline from links */
  font-family: inherit; /* Match font family */
  font-size: inherit; /* Match font size */
}

.right-footer a:hover,
.right-footer p:hover {
  text-decoration: underline !important; /* Optional: Add underline on hover for accessibility */
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.5) !important;

}

.ReactModal__Content {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 75%;
  height: 60%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: rgba(130, 122, 150, 1) !important;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

@media (max-width: 600px) {
  .header-stats {
      display: none;
  }
}



@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/* Responsive design adjustments */
@media (max-width: 1200px) {
  .tables-container {
      flex-direction: column;
      align-items: center;
  }

  .token-info,
  .input-table-container {
      margin: 10px 0;
  }
}

.button-container {
  display: flex;
  justify-content: flex-end; /* Align items to the right */
}

button {
  margin-left: 10px;
  padding: 10px 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  border-radius: 5px;
}

table, th, td {
  border: 1px solid #512da8;
  
}

th, td {
  padding: 8px;
  text-align: left;
  background-color: #976ff7;
  
}

th {
  background-color: #512da1;
  
}

input {
  padding: 10px;
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

button {
  padding: 10px 20px;
  background-color: #512da8;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #3e1a8a;
}


.token-table {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Mobile phone adjustments */
@media (max-width: 600px) {
  .button-container {
      justify-content: center; /* Center align items */
      flex-direction: column;  /* Stack buttons vertically */
  }

  button {
      margin: 5px 0;
      width: 100%; /* Full-width buttons */
      font-size: 12px !important;
  }

  table, th, td {
      font-size: 14px; /* Smaller font size for table elements */
  }

  .balance-container {
    display: flex;
    align-items: center;
    margin-right: 20px;
    border-radius: 5px;
    height: 40px;
    padding-left: 12px;
    background-color: #512da8;
    font-size: 12px !important;
  }
  .tables-container {
      padding: 10px; /* Add padding for better spacing */
  }

  .token-info,
  .input-table-container {
      width: 100%; /* Full-width containers */
      margin: 5px 0; /* Adjust margin for better spacing */
  }

  input {
      width: 90% !important; /* Full-width input fields */
      margin-bottom: 10px; /* Margin below input fields */
  }

  .token-table {
      margin-top: 10px; /* Reduced margin top */
  }
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
